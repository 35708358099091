import logo from './logo.svg';
import './App.css';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import ScanQr from './Components/ScanQr/ScanQr';
import DiscountCalc from './Components/DiscountCalc/DiscountCalc';
import Transaction from './Components/Transaction/Transaction';
import MerchantInfo from './Components/MerchantInfo/MerchantInfo';
import ContactUs from './Components/ContactUs/ContactUs';
import Login from './Components/Login/Login';
import UesrContextProvider from './Components/Context/UserContext';
import { Toaster } from 'react-hot-toast';
import ProutectedRoute from './Components/ProtuectedRoute/ProtuectedRoute';
import InversProtectedRoute from './Components/InversProtuectedRoute/InversProtuectedRoute';
import IdContextProvider from './Components/Context/IdContext';
import NameContextProvider from './Components/Context/nameContext';
const Routers = createBrowserRouter([
  {
    path: '', element: <Layout />, children: [
      { index: true, element: <ProutectedRoute><ScanQr /></ProutectedRoute> },
      { path: 'discount-calc', element: <ProutectedRoute><DiscountCalc /></ProutectedRoute> },
      { path: 'transactions', element: <ProutectedRoute><Transaction /></ProutectedRoute> },
      { path: 'merchant-info', element: <ProutectedRoute><MerchantInfo /></ProutectedRoute> },
      { path: 'contact-us', element: <ProutectedRoute><ContactUs /></ProutectedRoute> },
      { path: 'login', element: <InversProtectedRoute><Login /></InversProtectedRoute> },
    ]
  }
])
function App() {
  return <>
    <NameContextProvider>
      <UesrContextProvider>
        <Toaster />
        <IdContextProvider>
          <RouterProvider router={Routers}></RouterProvider>
        </IdContextProvider>
      </UesrContextProvider>
    </NameContextProvider>
  </>
}

export default App;
