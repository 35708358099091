import * as React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { UesrContext } from '../Context/UserContext';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar'; import axios from 'axios';
import toast from 'react-hot-toast';
import baseURL from '../../assists/Images/config';
;

const drawerWidth = 240;

function Layout(props) {
  let { userToken, setUserToken } = React.useContext(UesrContext);
  const token = localStorage.getItem('userToken');
  let navigate = useNavigate()
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [logo , setLogo] = React.useState();
  const [isClosing, setIsClosing] = React.useState(false);

  if (localStorage.getItem('userToken')) {
    setUserToken(localStorage.getItem('userToken'));
  }
  async function getLogo(){
    let {data} = await axios.get(`${baseURL}/info/show`)
    .catch((err)=> err)
    setLogo(data?.data?.info_data?.logo)
  }
  async function logOut() {
    let { data } = await axios.get(`${baseURL}/Merchent/logout`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    console.log(data);
    if (data.message == "Successfully logged out") {
      toast.success(data.message);
    }
    setUserToken(null)
    localStorage.removeItem('userToken');
    navigate('/login');
  }
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  React.useEffect(()=>{
    getLogo()
  },[])

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {userToken != null ? <>
          <NavLink className='text-decoration-none text-dark' to={'/'}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <i className="fa-solid fa-qrcode"></i>
                </ListItemIcon>
                <ListItemText primary={'Scan Qr'} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink className='text-decoration-none text-dark' to={'/discount-calc'}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <i className="fa-solid fa-percent"></i>
                </ListItemIcon>
                <ListItemText primary={'Discount Calculation'} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink className='text-decoration-none text-dark' to={'/transactions'}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <i className="fa-solid fa-arrows-turn-right"></i>
                </ListItemIcon>
                <ListItemText primary={'Transaction'} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink className='text-decoration-none text-dark' to={'/merchant-info'}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <i className="fa-regular fa-circle-question"></i>
                </ListItemIcon>
                <ListItemText primary={'Merchant Info'} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink className='text-decoration-none text-dark' to={'/contact-us'}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <i className="fa-solid fa-phone-volume"></i>
                </ListItemIcon>
                <ListItemText primary={'Contact us'} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <ListItem onClick={logOut} className='text-danger' disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <i className="text-danger fa-solid fa-arrow-right-from-bracket"></i>
              </ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItemButton>
          </ListItem>
        </> : <NavLink className='text-decoration-none text-dark' to={'/login'}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <i className="fa-solid fa-right-to-bracket"></i>
              </ListItemIcon>
              <ListItemText primary={'Login'} />
            </ListItemButton>
          </ListItem>
        </NavLink>}

      </List>
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return <>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `100%` },
          ml: { sm: `${drawerWidth}px` },
          display: { md: 'none' }
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <img src={logo} alt="Logo" />
          {drawer}
          <List>

          </List>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <img src={logo} alt="Logo" />
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)`, sm: `100%` } }}
      >
        <Toolbar />
        <Outlet></Outlet>
        <a href='https://www.allsafeeg.com/en' target='_blank' className='main-text text-end text-decoration-none fixed-bottom'>All Copy Rights Saved To All Safe ©2024.</a>
      </Box>

    </Box>

  </>;
}


export default Layout;