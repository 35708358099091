import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UesrContext } from '../Context/UserContext';
import { Helmet } from 'react-helmet';
import baseURL from '../../assists/Images/config';


function ContactUs() {
  let [number, setNumber] = useState(0);
  let { setUserToken } = useContext(UesrContext);
  const token = localStorage.getItem('userToken');
  async function getContactNumber() {
    let { data } = await axios.get(`${baseURL}/info/contactus`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    console.log(data);
    setNumber(data?.data?.phone);
  }
  useEffect(() => {
    getContactNumber()
  }, [])
  useEffect(() => {
    // Function to remove or hide the modal backdrop
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        // You can either remove the element
        modalBackdrop.remove();
        // Or add the 'd-none' class to hide it
        // modalBackdrop.classList.add('d-none');
      }
    };

    // Add event listener for component unmount
    return () => {
      removeModalBackdrop();
    };
  }, []);
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amigos | Contact Us</title>
    </Helmet>
    <section className='py-5 vh-90'>
      <div className="container">
        <h3 className='mt-4'>Contact Us</h3>
        <div className="row mt-4">
          <div className="col-md-6">
            <input type="text" value={`Owner Emergency Number ${number}`} disabled className='w-100 px-4 rounded-pill py-2' />
          </div>
        </div>
        <div className="alert alert-warning mt-4">
          ALERT:
          <p>If you have issue with dashboard please contact customer service</p>
        </div>
      </div>
    </section>
  </>
}

export default ContactUs;