import React, { useContext, useEffect, useState } from 'react';
import scan from '../../assists/Images/Group 1.png'
import icon from '../../assists/Images/_BaseIICSize.png'
import userImg from '../../assists/Images/Userimg.jpg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { IdContext } from '../Context/IdContext';
import { UesrContext } from '../Context/UserContext';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { Helmet } from 'react-helmet';
import baseURL from '../../assists/Images/config';

function ScanQr() {

  let { setUserId, setDiscount, setMaxCharge } = useContext(IdContext);
  let { setUserToken } = React.useContext(UesrContext);
  let [manualId, setManualId] = useState('');
  let [showScan, setShowScan] = useState(false);
  let [idData, setIdData] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('userToken');

  async function addManualId(id) {
    setIsLoading(true);
    let data = await axios.post(`${baseURL}/Merchent/scan/showinfo/user`, {
      UserId: id
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    if (data) {
      setIdData(data?.data?.data)
      setUserId(data?.data?.data.id);
      setDiscount(data?.data?.data.discount);
      setMaxCharge(data?.data?.data.Max_charge);
      setShowScan(true)
      setIsLoading(false);
    }

  }

  useEffect(() => {
    const scanner = new Html5QrcodeScanner('reader', {
      qrbox: {
        width: 100 + '%',
        height: 100 + '%'
      }
    })
    scanner.render(success, error);
    function success(result) {
      toast.success('scaned successful');
      addManualId(result);
    }
    function error(err) {
    }
  }, [])
  useEffect(() => {
    // Function to remove or hide the modal backdrop
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        // You can either remove the element
        modalBackdrop.remove();
        // Or add the 'd-none' class to hide it
        // modalBackdrop.classList.add('d-none');
      }
    };

    // Add event listener for component unmount
    return () => {
      removeModalBackdrop();
    };
  }, []);

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amigos | Scan</title>
    </Helmet>
    <section className='py-5 vh-90'>
      <div className="container">
        <h3 className='pt-4'>Scan Qr Code</h3>
        <div className='mt-5 d-flex align-items-center justify-content-center text-center'>
          <div>
            <div className="scan-img my-5">
              <div id='reader' className={isLoading?'d-none':''}></div>
            </div>
            <div className={`d-flex my-5 align-items-center justify-content-center  ${!isLoading?'d-none':''}`}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            {showScan ? <><button data-bs-toggle="modal" data-bs-target="#userInfo" className='main-bg border-0 text-white scan rounded-pill w-100'>Results</button>
              <p className='fw-bold my-3'>Or</p></> : ''}
            <button className='manual main-text rounded-pill w-100' data-bs-toggle="modal" data-bs-target="#exampleModal">Insert User Id Manually</button>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title fs-5" id="exampleModalLabel"> <img src={icon} alt="icon" /> Insert User Id</h2>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <input type="number" onChange={(e) => { setManualId(e.target.value) }} placeholder='User ID' className='w-100 px-4 rounded-pill py-2' />
                      </div>
                    </div>
                    <div className="text-center">
                      <button onClick={() => addManualId(manualId)} data-bs-toggle="modal" data-bs-target="#userInfo" className='main-bg text-white px-5 py-2 border-0 rounded-pill mt-4'>Add</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="userInfo" tabIndex="-1" aria-labelledby="userInfoLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h2 className="modal-title fs-5" id="userInfoLabel"> <img src={icon} alt="icon" /> Insert User Id</h2>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {isLoading ? <>
                      <div className='d-flex align-items-center justify-content-center'>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </> : <>
                      <div className="user-img mx-auto my-4 rounded-circle overflow-hidden">
                        <img src={idData.user_image} className='w-100' alt="userimg" />
                      </div>
                      <div className="row g-4">
                        <div className="col-md-6">
                          <p className='text-start'>Username:</p>
                          <input type="text" readOnly value={idData?.user_name} className='w-100 px-4 rounded-pill py-2' />
                        </div>
                        <div className="col-md-6">
                          <p className='text-start'>User Id:</p>
                          <input type="text" readOnly value={idData?.UserId} className='w-100 px-4 rounded-pill py-2' />
                        </div>
                        <div className="col-md-6">
                          <p className='text-start'>Discount Percentage:</p>
                          <input type="text" readOnly value={idData?.discount} className='w-100 px-4 rounded-pill py-2' />
                        </div>
                        <div className="col-md-6">
                          <p className='text-start'>Max Charge:</p>
                          <input type="text" readOnly value={idData?.Max_charge} className='w-100 px-4 rounded-pill py-2' />
                        </div>
                      </div>
                      <div className="text-center">
                        <Link to={'/discount-calc'} className='text-decoration-none'>
                          <button data-bs-dismiss="modal" aria-label="Close" className='main-bg text-white px-5 py-2 border-0 rounded-pill mt-4'>Discount Calculation</button>
                        </Link>
                      </div>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>
}

export default ScanQr;