import React, { useEffect, useState } from 'react';
import icon from '../../assists/Images/_BaseIIC-SizeStateColor.png'
import axios from 'axios';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { UesrContext } from '../Context/UserContext';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { formatDateToTimeAmPm } from '../../util/functions';
import baseURL from '../../assists/Images/config';

function MerchantInfo() {
  let { setUserToken } = React.useContext(UesrContext);
  const [isLoading, setIsLoading] = useState(false);
  const [merchData, setMerchData] = useState({})
  const token = localStorage.getItem('userToken');
  async function getMerchantInfo() {
    setIsLoading(true)
    let data = await axios.get(`${baseURL}/Merchent/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    setMerchData(data?.data?.data)
    console.log(data?.data?.data);
    setIsLoading(false)
  }
  async function editMerchantInfo(values) {
    let data = await axios.post(`${baseURL}/Merchent/edit/profile`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    if (data?.data?.message == "Edit profile merchent sucessful") {
      toast.success(data?.data?.message);
      setMerchData(data?.data?.data);
    }
  }

  let validationSchema = yup.object({
    name: yup.string().required('Name is Required'),
    domain_address: yup.string().required('email is required').email('please use a valid email')
  })
  useEffect(() => {
    if (merchData) {
      formik.setValues({
        name: '',
        domain_address: '',
        phone: merchData.phone
      });
    }
  }, [merchData]);

  let formik = useFormik({
    initialValues: {
      name: '',
      domain_address: '',
      phone: merchData.phone
    }, onSubmit: editMerchantInfo,
    validationSchema
  })



  useEffect(() => {
    getMerchantInfo()
  }, [])
  useEffect(() => {
    // Function to remove or hide the modal backdrop
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        // You can either remove the element
        modalBackdrop.remove();
        // Or add the 'd-none' class to hide it
        // modalBackdrop.classList.add('d-none');
      }
    };

    // Add event listener for component unmount
    return () => {
      removeModalBackdrop();
    };
  }, []);
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amigos | Merchant Info</title>
    </Helmet>
    <section className='py-5 vh-90'>
      <div className="container">
        <h3 className='mt-4'>Merchant Info</h3>
        {isLoading ? <>
          <div className='d-flex align-items-center justify-content-center'>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </> : <>
          <div className="user-img mx-auto my-4 rounded-circle overflow-hidden">
            <img src={merchData.logo} className='w-100' alt="userimg" />
          </div>
          <div className="row g-4">
            <div className="col-lg-6">
              <div className='position-relative'>
                <input type="text" disabled value={`Merchant Name: ${merchData.name}`} className='w-100 px-4 py-2 rounded-pill' />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='position-relative'>
                <input type="text" disabled value={`Engagement starting Date: ${
                    formatDateToTimeAmPm(merchData.join_date)

                    }`} className='w-100 px-4 py-2 rounded-pill' />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='position-relative'>
                <input type="text" onChange={formik.handleChange} name='phone' disabled value={`Merchant responsible Person Number : ${merchData.phone}`} className='w-100 px-4 py-2 rounded-pill' />
              </div>
            </div>
            <div className="col-lg-6">
              <div className='position-relative'>
                <input type="text" disabled value={`Email Adress:${merchData.email}`} className='w-100 px-4 py-2 rounded-pill' />
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className='position-relative'>
                <input type="text" disabled value={`Password: *************`} className='w-100 px-4 py-2 rounded-pill' />
              </div>
            </div> */}
          </div>
          {/* <div className="edit-btn">
            <button className='manual main-text rounded-3 my-3' data-bs-toggle="modal" data-bs-target="#editInfo">Edit Merchant Info</button>
          </div> */}
          <div className="modal fade" id="editInfo" tabIndex="-1" aria-labelledby="editInfoLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h2 className="modal-title fs-5" id="editInfoLabel"> <img src={icon} alt="icon" /> Edit User Info</h2>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row g-4">
                      <div className="col-lg-6">
                        <input type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} name='name' placeholder='Name' className='w-100 px-4 rounded-pill py-2' />
                        {formik.errors.name && formik.touched.name ? <p className='text-danger'>{formik.errors.name}</p> : ''}
                      </div>
                      <div className="col-lg-6">
                        <input type="text" onChange={formik.handleChange} onBlur={formik.handleBlur} name='domain_address' placeholder='Email Address' className='w-100 px-4 rounded-pill py-2' />
                        {formik.errors.domain_address && formik.touched.domain_address ? <p className='text-danger'>{formik.errors.domain_address}</p> : ''}
                      </div>
                    </div>
                    <div className="text-center">
                      <button type='submit' className='main-bg text-white px-5 py-2 border-0 rounded-pill mt-4' data-bs-dismiss="modal" aria-label="Close">Change</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </>
        }
      </div>
    </section>
  </>
}

export default MerchantInfo;