import { createContext, useState } from "react";

export let IdContext = createContext()

export default function IdContextProvider(props){
    const [userId, setUserId] = useState(0);
    const [discount, setDiscount] = useState('');
    const [maxCharge, setMaxCharge] = useState('');
    
    return<IdContext.Provider value={{userId, setUserId, discount, setDiscount, maxCharge, setMaxCharge }}>
        {props.children}
    </IdContext.Provider>
}