import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { UesrContext } from '../Context/UserContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import baseURL from '../../assists/Images/config';



function Login() {
  let navigate = useNavigate();
  let { setUserToken } = useContext(UesrContext);
  let [phone, setPhone] = React.useState('')
  async function merchantLogin(values) {
    let { data } = await axios.post(`${baseURL}/Merchent/login`, values)
      .catch((err) => toast.error(err.response.data.message));
    if (data.message == 'Login successful') {
      toast.success(data.message)
      localStorage.setItem('userToken', data.data.access_token);
      setUserToken(data.data.access_token)
      navigate('/')
    }
  }


  let validationSchema = yup.object({
    password: yup.string().required('The password is required').min(6, 'must be at least 6 chars'),
    domain_address: yup.string().required('The email is required').email('Please use a valid email'),
  })

  let formik = useFormik({
    initialValues: {
      domain_address: '',
      password: '',
    }, validationSchema,
    onSubmit: merchantLogin
  })


  // get merhant data from api 

    const getMerchantData = async () => {
      try {
        let { data } = await axios.get(
          `${baseURL}/info/contactus`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          }
        );
        console.log(data);
        setPhone(data?.data?.phone);
      } catch (error) {
        console.error(error);
        // Handle the error here, e.g. show an error message to the user
      }
    };
  


  useEffect(() => {
    getMerchantData()
  }, [])


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amigos | Login</title>
      </Helmet>
      <section className="py-5 vh-90">
        <div className="container">
          <h3 className="mt-4">Login</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="row g-4 mt-4">
              <div className="col-md-6">
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="email"
                  name="domain_address"
                  placeholder="Email Address"
                  className="w-100 px-4 rounded-pill py-2"
                />
                {formik.errors.domain_address &&
                formik.touched.domain_address ? (
                  <p className="text-danger">{formik.errors.domain_address}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-6">
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="w-100 px-4 rounded-pill py-2"
                />
                {formik.errors.password && formik.touched.password ? (
                  <p className="text-danger">{formik.errors.password}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="main-bg text-white px-5 py-2 border-0 rounded-pill mt-4"
              >
                Login
              </button>
            </div>
          </form>

          <div className="alert alert-warning mt-4">
            ALERT:
            <p>
              If you have issue with login please contact customer service via
              <a href={`tel:+20${phone}`} className="px-2 ">
                {phone}
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;