import React, { useContext, useEffect, useState } from 'react';
import icon from '../../assists/Images/_BaseIICSize.png'
import { IdContext } from '../Context/IdContext';
import axios from 'axios';
import { UesrContext } from '../Context/UserContext';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import baseURL from '../../assists/Images/config';
function DiscountCalc() {
  const navigate = useNavigate();

  let { userId, discount, maxCharge } = useContext(IdContext);
  let { setUserToken } = useContext(UesrContext);
  const [resetData, setResetData] = useState({})
  let [price, setPrice] = useState(0);
  let [priceDisc, setPriceDisc] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('userToken');
  async function calcDisc() {
    if (!userId, !discount, !maxCharge) {
      toast.error('Please Scan Qr or insert Id first')

    } else {
      setIsLoading(true);
      let data = await axios.post(`${baseURL}/Merchent/scan/calc/discount/${userId}`, {
        price: price,
        discount: discount,
        max_charge: maxCharge
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).catch((err) => {
        console.log(err);
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
      if (data) {
        console.log(data);
      }
      toast.success(data.data.message)
      setPriceDisc(data?.data?.data?.transactionId)
      setIsLoading(false)

      // navigate('/')
    }

  }
  // Function to remove or hide the modal backdrop
  const removeModalBackdrop = () => {
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      // You can either remove the element
      modalBackdrop.remove();
      // Or add the 'd-none' class to hide it
      // modalBackdrop.classList.add('d-none');
    }
  };
  useEffect(() => {

    // Add event listener for component unmount
    return () => {
      removeModalBackdrop();
    };
  }, []);
  const getDataReset = async (id) => {
    console.log(id);
    let data = await axios.get(`${baseURL}/reset/summary/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => setResetData(res?.data?.data)).then((data => setTimeout(() => {
      resetCreationOrder()
    }, 100)))
  }
  const resetCreationOrder = () => {
    const printContent = document.getElementById('orderContent').innerHTML;
    const printWindow = window.open('', '', 'width=700');
    printWindow.document.write('<html><head><title>Print Transaction</title>');
    printWindow.document.write('<style>.printable { width: 7cm; border: 1px solid #000; padding: 40px; margin: 30px auto; border:1px solid gray; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amigos | Discount Calculation</title>
    </Helmet>
    <section className='py-5 vh-90'>
      <div className="container">
        <h3 className='pt-4'>Discount Calculation</h3>
        <div className="row g-4 mt-5">
          <div className="col-md-6">
            <p>Price Of Order:</p>
            <input type="number" onChange={(e) => setPrice(e.target.value)} className='w-100 px-4 rounded-pill py-2' />
          </div>
          <div className="col-md-6">
            <p>Discount Percentage:</p>
            <input type="text" readOnly value={discount} className='w-100 px-4 rounded-pill py-2' />
          </div>
          <div className="col-md-6">
            <p>Max Charge:</p>
            <input type="text" readOnly value={maxCharge} className='w-100 px-4 rounded-pill py-2' />
          </div>
        </div>
        <button onClick={calcDisc} data-bs-toggle="modal" data-bs-target="#calcDisc" className='main-bg text-white w-100 py-2 border-0 rounded-pill mt-4'>Calculate</button>
        <div className="modal fade" id="calcDisc" tabIndex="-1" aria-labelledby="calcDisc" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title fs-5" id="calcDisc"> <img src={icon} alt="icon" /> Transaction Details</h2>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {isLoading ? <div className='d-flex align-items-center justify-content-center'>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div> : <>
                  <div className='row align-items-center justify-content-between'>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>id: {priceDisc?.id}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>merchantName: {priceDisc?.merchantName}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>User Name: {priceDisc?.userName}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>Amount: {priceDisc?.amount}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>discount: {priceDisc?.discount}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>price after discount: {priceDisc?.price_after_discount}</p>
                    </div>
                    <div className="col-lg-6">
                      <p className='main-text fw-bold'>price before discount: {priceDisc?.price_before_discount}</p>
                    </div>
                  </div>
                  <div>
                    <button  onClick={(e) => getDataReset(priceDisc?.id)} className='main-bg text-white w-100 py-2 border-0 rounded-pill mt-4'>Print</button>
                  </div>
                </>}
                <div id="orderContent" class="printable d-none">{
                  <div>
                    <p style={{ fontSize: "25px" }}>User Name: {resetData.userName}</p>
                    <p style={{ fontSize: "25px" }}>Merchant Name: {resetData.merchantName}</p>
                    <p style={{ fontSize: "25px" }}>Transaction Number: {resetData.transcationNumber}</p>
                    <p style={{ fontSize: "25px" }}>Price Before Discount: {resetData.price_before_discount}</p>
                    <p style={{ fontSize: "25px" }}>Discount: {resetData.discount}</p>
                    <p style={{ fontSize: "25px" }}>Amount: {resetData.amount}</p>
                    <p style={{ fontSize: "25px" }}>Price After Discount: {resetData.price_after_discount}</p>
                    <p style={{ fontSize: "25px" }}>Date: {resetData.date}</p>
                    <p style={{ fontSize: "25px" }}>Status: {resetData.status}</p>
                  </div>
                }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
}

export default DiscountCalc;