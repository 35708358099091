import * as React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { UesrContext } from '../Context/UserContext';
import { Helmet } from 'react-helmet';
import baseURL from '../../assists/Images/config';
import Swal from 'sweetalert2';


ChartJS.register(ArcElement, Tooltip, Legend);



const columns = ["ID", "User Name", "Transaction Id", "Price Before Discount", "Discount %", 'Date', 'Price After Discount', 'status', 'action'];


function Transaction() {


  const [chartData, setChartData] = useState([])
  const [dataTable, setDataTable] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [merchantName, setMerchantName] = useState('');
  const [resetData, setResetData] = useState({})
  let [isLoading, setIsLoading] = useState(false);
  const rowsPerPage = 10;
  const totalRows = dataTable?.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = dataTable?.slice(startIndex, endIndex);
  const token = localStorage.getItem('userToken');
  let { setUserToken } = React.useContext(UesrContext);

  const handleClickNext = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handleClickPrev = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  async function getTransactions() {
    setIsLoading(true);
    let data = await axios.get(`${baseURL}/Merchent/list/transcation`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    setDataTable(data?.data?.data?.Transaction_list);
    setMerchantName(data?.data?.data.Transaction_list[0]?.merchantName)
    setChartData([data?.data?.data.chart.today, data?.data?.data.chart.this_month, data?.data?.data.chart.this_year])
    setIsLoading(false);
  }
  async function filterTransaction() {
    if (fromDate == '' || toDate == '') {
      toast.error('Please select Date range first')
    } else {
      let data = await axios.post(`${baseURL}/owner/Merchent/listfiltertranaction`, {
        from: fromDate,
        to: toDate,
        merchent_name: merchantName
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .catch((err) => {
          toast.error(err.response?.data.message)
          if (err.response?.data.message == 'unautherized') {
            localStorage.removeItem('userToken');
            setUserToken(null)
          }
        });
      console.log(data?.data?.data);
      setDataTable(data?.data?.data);
    }
  }
  async function deleteFromList(id) {
    setIsLoading(true)
    let data = await axios.get(`${baseURL}/Merchent/delete/transcation/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .catch((err) => {
        toast.error(err.response?.data.message)
        if (err.response?.data.message == 'unautherized') {
          localStorage.removeItem('userToken');
          setUserToken(null)
        }
      });
    if (data.data.message == "delete transaction") {
      toast.success(data.data.message)
      getTransactions()
    }
    setIsLoading(false)
  }
  const chart = {
    labels: ['Day', 'Month', 'Year'],
    datasets: [
      {

        data: chartData,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };



  React.useEffect(() => {
    getTransactions()
  }, [])
  React.useEffect(() => {
    // Function to remove or hide the modal backdrop
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    };

    // Add event listener for component unmount
    return () => {
      removeModalBackdrop();
    };
  }, []);

  const sendVoid = async (id) => {
    let data = await axios.get(`${baseURL}/Merchent/changeStatus/transaction/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => toast.success(res?.data?.message)).then(() => getTransactions())
    console.log(data, 'hi');
  }
  const showSwal = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to change the status?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.isConfirmed) {
        sendVoid(id);
      }
    });
  };
  const changeStatusFn = async (status) => {
    let data = await axios.post(`${baseURL}/list/filterbystatus/transaction`, {
      "status": status
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => setDataTable(res?.data?.data))
    console.log(status);
  }
  const getDataReset = async (id) => {
    console.log(id);
    let data = await axios.get(`${baseURL}/reset/summary/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => setResetData(res?.data?.data)).then((data => setTimeout(() => {
      resetCreationOrder()
    }, 100)))
  }
  const resetCreationOrder = () => {
    const printContent = document.getElementById('orderContent').innerHTML;
    const printWindow = window.open('', '', 'width=700');
    printWindow.document.write('<html><head><title>Print Transaction</title>');
    printWindow.document.write('<style>.printable { width: 7cm; border: 1px solid #000; padding: 40px; margin: 30px auto; border:1px solid gray; }</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amigos | Transactions</title>
    </Helmet>
    <section className='py-5 vh-90'>
      <div className="container-fluid">
        {/* <div class="loader"></div> */}
        <h3 className='pt-4'>Transaction</h3>
        <h4 className=''>Filter by Date:</h4>
        <div className='d-flex align-items-center py-2'>
          <div className='d-flex align-items-center mx-2'>
            <p className='fw-bold'>From:</p>
            <input type="date" onChange={(e) => { let format = e.target.value.split("-").reverse().join('-'); setFromDate(format) }} className='form-control' />
          </div>
          <div className='d-flex align-items-center mx-2'>
            <p className='fw-bold'>To:</p>
            <input type="date" onChange={(e) => { let format = e.target.value.split("-").reverse().join('-'); setToDate(format) }} className='form-control' />
          </div>
          <div className='d-flex align-items-center mx-2'>
            <button onClick={filterTransaction} className='main-bg text-white px-5 py-1 border-0 rounded-2'>Filter</button>
          </div>
          <select name="" id="" className="statusChange form-select" onChange={(e) => e.target.value != '' ? changeStatusFn(e.target.value) : getTransactions()}>
            <option value="">All</option>
            <option value="void">Void</option>
            <option value="done">Done</option>
          </select>

        </div>
        <div className="row g-4">
          <div className="col-xl-8">
            <div className="shadow ">
              <div className="table-responsive h-400 rounded-4 overflow-y-auto bg-white">
                <table className='table  bg-white'>
                  <thead>
                    <tr>
                      {columns.map((col, i) =>
                        <th key={i} className='text-center py-4 main-text'>{col}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? <>
                      <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
                        <div className="spinner-border text-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </> : <>
                      {currentRows.map((data, i) =>
                        <tr key={i}>
                          <td className='text-center'>{i + 1}</td>
                          <td className='text-center'>{data.username}</td>
                          <td className='text-center'>{data.transcation_id}</td>
                          <td className='text-center'>{data.price_before_discount}</td>
                          <td className='text-center'>{data.discount}</td>
                          <td className='text-center'>{data.date}</td>
                          <td className='text-center'>{data.price_after_discount}</td>
                          <td className={`text-center ${data.status == 'void' ? 'bg-warning' : 'bg-success text-white cursor-pointer'}`} onClick={() => data.status == 'done' ? showSwal(data.id) : ''}>{data.status}</td>
                          <td>
                            <button className="createReset btn btn-success ms-3" onClick={(e) => getDataReset(data.id)}><i class="fa-solid fa-print"></i></button>
                            <div id="orderContent" class="printable d-none">{
                              <div>
                                <p style={{ fontSize: "25px" }}>User Name: {resetData.userName}</p>
                                <p style={{ fontSize: "25px" }}>Merchant Name: {resetData.merchantName}</p>
                                <p style={{ fontSize: "25px" }}>Transaction Number: {resetData.transcationNumber}</p>
                                <p style={{ fontSize: "25px" }}>Price Before Discount: {resetData.price_before_discount}</p>
                                <p style={{ fontSize: "25px" }}>Discount: {resetData.discount}</p>
                                <p style={{ fontSize: "25px" }}>Amount: {resetData.amount}</p>
                                <p style={{ fontSize: "25px" }}>Price After Discount: {resetData.price_after_discount}</p>
                                <p style={{ fontSize: "25px" }}>Date: {resetData.date}</p>
                                <p style={{ fontSize: "25px" }}>Status: {resetData.status}</p>
                              </div>
                            }</div>
                          </td>
                        </tr>
                      )
                      }
                    </>
                    }
                  </tbody>
                </table>
              </div>
              <div className="d-flex py-3 align-items-center justify-content-center bg-white">
                <ul className="pagination justify-content-between">
                  <li className="page-item mx-3">
                    <button className="page-link" onClick={handleClickPrev} disabled={currentPage === 1}>Previous</button>
                  </li>
                  {[...Array(totalPages).keys()].map(page => (
                    <li key={page} className={`page-item mx-3 ${currentPage === page + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => setCurrentPage(page + 1)}>{page + 1}</button>
                    </li>
                  ))}
                  <li className="page-item mx-3">
                    <button className="page-link" onClick={handleClickNext} disabled={currentPage === totalPages}>Next</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className='bg-white chart rounded-2 p-2 shadow'>
              <p className='main-text text-center fw-bold'>Total Discount Amount Per Day & Month & Year</p>
              <Doughnut data={chart} />
            </div>
          </div>
        </div>
      </div>

    </section>
  </>
}

export default Transaction;